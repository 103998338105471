var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-layout',{attrs:{"row":"","wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":"","lg12":""}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"loading":_vm.loading,"no-data-text":"No packages available","items":_vm.packages,"items-per-page":10},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t(item.name)))])]}},{key:"item.period",fn:function(ref){
var item = ref.item;
return [(item.price == 'Free')?_c('v-card-text',[_vm._v(_vm._s(_vm.$t("valid for 14 days")))]):(item.name == 'Lifetime')?_c('v-card-text',[_vm._v(_vm._s(_vm.$t("once")))]):_c('v-card-text',[_vm._v(_vm._s(_vm.$t("per month")))])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"small":"","left":"","color":_vm.$store.state.primaryColor},on:{"click":function($event){return _vm.editPackageDetails(item.id)}}},[_vm._v("mdi-pencil")]),_c('v-icon',{attrs:{"small":"","left":"","color":"red"},on:{"click":function($event){return _vm.openDeletePackageDialog(item.name, item.id)}}},[_vm._v("mdi-delete")])]}}],null,true)})],1)],1),_c('v-snackbar',{attrs:{"top":"","centered":"","color":"success lighten-5 success--text text--darken-3"},model:{value:(_vm.snackSuccess),callback:function ($$v) {_vm.snackSuccess=$$v},expression:"snackSuccess"}},[_c('span',[_vm._v(_vm._s(_vm.$t("package successfully deleted"))+".")]),_c('v-btn',{attrs:{"icon":"","color":"success darken-3"},on:{"click":function($event){_vm.snackSuccess = false}}},[_c('v-icon',[_vm._v("close")])],1)],1),_c('v-snackbar',{attrs:{"top":"","centered":"","color":"red lighten-5 error--text text--darken-3","timeout":"4000"},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attr = ref.attr;
return [_c('v-btn',_vm._b({attrs:{"icon":"","color":"error darken-3"},on:{"click":function($event){_vm.snackError = false}}},'v-btn',attr,false),[_c('v-icon',[_vm._v("close")])],1)]}}]),model:{value:(_vm.snackError),callback:function ($$v) {_vm.snackError=$$v},expression:"snackError"}},[_c('span',[_vm._v(_vm._s(_vm.$t("could not delete package, Try again later"))+".")])]),_c('v-dialog',{attrs:{"persistent":"","max-width":"290"},model:{value:(_vm.delDialog),callback:function ($$v) {_vm.delDialog=$$v},expression:"delDialog"}},[_c('v-card',[_c('div',{staticClass:"text-center"},[_c('v-btn',{staticClass:"my-5",attrs:{"outlined":"","large":"","fab":"","color":"red darken-4"}},[_c('v-icon',[_vm._v("warning")])],1)],1),_c('v-card-text',{staticClass:"text-center"},[_c('p',[_vm._v(_vm._s(_vm.$t("are you sure"))+"?")]),_c('p',[_vm._v(" "+_vm._s(_vm.$t("do you really want to delete the"))+" "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.packageName))]),_vm._v(" "+_vm._s(_vm.$t("package"))+"? ")])]),_c('v-card-actions',[_c('v-btn',{attrs:{"color":_vm.$store.state.secondaryColor,"text":"","small":""},on:{"click":function($event){_vm.delDialog = false}}},[_vm._v(" "+_vm._s(_vm.$t("cancel"))+" ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":_vm.$store.state.primaryColor,"text":"","small":"","loading":_vm.loading},on:{"click":function($event){return _vm.deletePackage()}}},[_vm._v(" "+_vm._s(_vm.$t("confirm delete"))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }