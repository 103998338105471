<template>
  <v-container>
    <v-layout row wrap justify-center>
      <v-flex xs12 sm12 md12 lg12>
        <v-data-table
          :headers="headers"
          :loading="loading"
          no-data-text="No packages available"
          :items="packages"
          :items-per-page="10"
        >
          <template v-slot:[`item.name`]="{ item }">
            <span class="font-weight-bold">{{ $t(item.name) }}</span>
          </template>
          <template v-slot:[`item.period`]="{ item }">
            <v-card-text v-if="item.price == 'Free'">{{
              $t("valid for 14 days")
            }}</v-card-text>
            <v-card-text v-else-if="item.name == 'Lifetime'">{{
              $t("once")
            }}</v-card-text>
            <v-card-text v-else>{{ $t("per month") }}</v-card-text>
          </template>

          <template v-slot:[`item.action`]="{ item }">
            <v-icon
              small
              left
              :color="$store.state.primaryColor"
              @click="editPackageDetails(item.id)"
              >mdi-pencil</v-icon
            >
            <v-icon
              small
              left
              color="red"
              @click="openDeletePackageDialog(item.name, item.id)"
              >mdi-delete</v-icon
            >
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>

    <v-snackbar
      v-model="snackSuccess"
      top
      centered
      color="success lighten-5 success--text text--darken-3"
    >
      <span>{{ $t("package successfully deleted") }}.</span>

      <v-btn icon color="success darken-3" @click="snackSuccess = false">
        <v-icon>close</v-icon>
      </v-btn>
    </v-snackbar>

    <v-snackbar
      v-model="snackError"
      top
      centered
      color="red lighten-5 error--text text--darken-3"
      timeout="4000"
    >
      <span>{{ $t("could not delete package, Try again later") }}.</span>

      <template v-slot:action="{ attr }">
        <v-btn
          icon
          color="error darken-3"
          v-bind="attr"
          @click="snackError = false"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <v-dialog v-model="delDialog" persistent max-width="290">
      <v-card>
        <div class="text-center">
          <v-btn class="my-5" outlined large fab color="red darken-4">
            <v-icon>warning</v-icon>
          </v-btn>
        </div>

        <v-card-text class="text-center">
          <!-- <v-icon>delete</v-icon> -->
          <p>{{ $t("are you sure") }}?</p>
          <p>
            {{ $t("do you really want to delete the") }}
            <span class="font-weight-bold">{{ packageName }}</span>
            {{ $t("package") }}?
          </p>
        </v-card-text>
        <v-card-actions>
          <v-btn
            :color="$store.state.secondaryColor"
            text
            small
            @click="delDialog = false"
          >
            {{ $t("cancel") }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            :color="$store.state.primaryColor"
            text
            small
            :loading="loading"
            @click="deletePackage()"
          >
            {{ $t("confirm delete") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import db from "@/plugins/fb";
import format from "date-fns/format";
export default {
  data: () => ({
    logs: [],
    packageId: "",
    packageName: "",
    loading: false,
    search: "",
    delDialog: false,
    packages: [],
    colors: [
      "success",
      "light-blue",
      "primary darken-2",
      "pink darken-4",
      "error darken-4",
      "teal darken-1",
    ],
    snackSuccess: false,
    snackError: false,
  }),

  created() {
    this.getPackages();
  },

  computed: {
    headers() {
      return [
        {
          text: this.$t("package name"),
          value: "name",
          class: "blue-grey darken-3 white--text font-weight-regular",
        },
        {
          text: this.$t("package price"),
          value: "price",
          class: "blue-grey darken-3 white--text font-weight-regular",
        },
        {
          text: this.$t("allowed branches"),
          value: "description.branches",
          class: "blue-grey darken-3 white--text font-weight-regular",
        },
        {
          text: this.$t("allowed users"),
          value: "description.users",
          class: "blue-grey darken-3 white--text font-weight-regular",
        },
        {
          text: this.$t("allowed storage"),
          value: "description.storage",
          class: "blue-grey darken-3 white--text font-weight-regular",
        },
        {
          text: this.$t("period"),
          value: "period",
          class: "blue-grey darken-3 white--text font-weight-regular",
        },
        {
          text: this.$t("action"),
          value: "action",
          class: "blue-grey darken-3 white--text font-weight-regular",
        },
      ];
    },
  },
  methods: {
    getPackages() {
      this.loading = true;
      this.packages = [];
      db.collection("packages")
        .orderBy("description.storage")
        .get()
        .then((snapshot) => {
          snapshot.forEach((snaps) => {
            this.packages.push({
              id: snaps.id,
              ...snaps.data(),
            });
            this.loading = false;
          });
        });
    },
    openDeletePackageDialog(packageName, packageId) {
      this.packageName = packageName;
      this.packageId = packageId;
      this.delDialog = true;
    },
    deletePackage() {
      this.loading = true;
      db.collection("packages")
        .doc(this.packageId)
        .delete()
        .then(() => {
          this.snackSuccess = true;
          this.loading = false;
          this.delDialog = false;
          setTimeout(() => this.getPackages(), 2000);
        })
        .catch(() => {
          this.loading = false;
          this.delDialog = false;
          this.snackError = true;
        });
    },

    DateFormat(data) {
      return data != null ? format(data.toDate(), "eee dd-MMM-yyyy hh:mm") : "";
    },
    editPackageDetails(packageId) {
      this.$router.push({
        name: "updatePackages",
        params: { packageId: packageId },
      });
    },
    goTo(packageId) {
      this.$router.push({
        name: "AssignPackages",
        params: { packageId: packageId },
      });
    },
  },
};
</script>

<style></style>
